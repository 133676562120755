import * as SentrySvelte from '@sentry/svelte';
import { BrowserTracing } from '@sentry/tracing';
import type { HandleClientError } from '@sveltejs/kit';

// SentrySvelte.init({
// 	dsn: import.meta.env.VITE_SENTRY_DSN,
// 	release: __VERSION__ ?? '0.0.0',
// 	tracesSampleRate: parseFloat(import.meta.env.VITE_TRACE_SAMPLE_RATE),
// 	replaysSessionSampleRate: 0.1,
// 	replaysOnErrorSampleRate: 1.0,
// 	integrations: [new BrowserTracing(), new SentrySvelte.Replay()]
// });

// SentrySvelte.setTag('svelteKit', 'browser');

// This will catch errors in `load` functions from `+page.ts` files
export const handleError = (({ error, event }) => {
	SentrySvelte.captureException(error, { contexts: { sveltekit: { event } } });
	const message = error instanceof Error ? error.message : 'An error occurred';
	return {
		message
	};
}) satisfies HandleClientError;
